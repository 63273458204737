import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const tracciatoBustaPagaReportsRoutesDictionary =
  moduleRouteDictionaryFactory(
    'tracciatoBustaPagaReports',
    'tracciatoBustaPagaReports',
    {
      reports: 'reports-monthly',
    },
  );
