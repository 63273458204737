import { useAuthStore, WelfarePlan } from '@tundr/auth';
import { voucherReportsRoutesDictionary } from './voucher-reports-routes.dictionary.ts';
import { RouteRecordRaw } from 'vue-router';

export const voucherReportsRoutesConfig: RouteRecordRaw = {
  ...voucherReportsRoutesDictionary.voucherReports.root,
  redirect: {
    name: voucherReportsRoutesDictionary.voucherReports.reports.name,
  },
  beforeEnter: () => {
    const authStore = useAuthStore();
    return authStore.currentWelfarePlan === WelfarePlan.COMPLETE;
  },

  children: [
    {
      ...voucherReportsRoutesDictionary.voucherReports.reports,
      component: () =>
        import('../../pages/VoucherReports/List/VoucherReportListPage.vue'),
    },
  ],
};
