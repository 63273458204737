import { useAuthStore, WelfarePlan } from '@tundr/auth';
import { RouteRecordRaw } from 'vue-router';
import { tracciatoBustaPagaReportsRoutesDictionary } from './tracciato-busta-paga-reports-routes.dictionary.ts';

export const tracciatoBustaPagaReportsRoutesConfig: RouteRecordRaw = {
  ...tracciatoBustaPagaReportsRoutesDictionary.tracciatoBustaPagaReports.root,
  redirect: {
    name: tracciatoBustaPagaReportsRoutesDictionary.tracciatoBustaPagaReports
      .reports.name,
  },
  beforeEnter: () => {
    const authStore = useAuthStore();
    return authStore.currentWelfarePlan === WelfarePlan.COMPLETE;
  },

  children: [
    {
      ...tracciatoBustaPagaReportsRoutesDictionary.tracciatoBustaPagaReports
        .reports,
      component: () =>
        import(
          '../../pages/TracciatoBustaPagaZucchetti/List/TracciatoBustaPagaZucchettiListPage.vue'
        ),
    },
  ],
};
