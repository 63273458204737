import {
  HttpResponseError,
  HttpStatusCode,
  HttpValidationErrorData,
} from '../types';
import { HTTP_CORRELATION_ID_HEADER_KEY } from '../config.ts';

type TFunction = (
  key: string,
  interpolationParams?: Record<string, unknown>,
) => string;

export type OnHttpErrorFn<ErrorType = unknown> = (
  error: HttpResponseError<ErrorType>,
  t: TFunction,
  notifyFn: (payload: NotifyFnPayload) => unknown,
) => void;

export type OnHttpErrorReturnType<ErrorType = unknown> = [
  statusCode: number,
  handler: OnHttpErrorFn<ErrorType>,
];

export function onHttpError(
  statusCode: HttpStatusCode,
  handler: OnHttpErrorFn,
): OnHttpErrorReturnType {
  return [statusCode, handler];
}

type NotifyFnPayload = {
  message: string;
  description?: string;
  extraDesc?: string;
};

export function onValidationError(
  handler: OnHttpErrorFn<HttpValidationErrorData>,
): OnHttpErrorReturnType<HttpValidationErrorData> {
  return [HttpStatusCode.BadRequest, handler];
}

export const validationErrorNotificationHandler: (
  namespace: string,
) => OnHttpErrorFn<HttpValidationErrorData> =
  (i18nNamespace) => (error, t, notifyFn) => {
    const prefix = i18nNamespace ? `${i18nNamespace}.` : '';
    const correlationId =
      error.response && error.response.headers
        ? error.response.headers[HTTP_CORRELATION_ID_HEADER_KEY]
        : null;
    const extraDesc = `${t('http:errors.correlation_id')} ${correlationId}`;
    notifyFn({
      message: t(
        `${prefix}${error.response?.data.errorCode}.title`,
        error.response?.data.metadata,
      ),
      description: t(
        `${prefix}${error.response?.data.errorCode}.description`,
        error.response?.data.metadata,
      ),
      extraDesc: correlationId ? extraDesc : undefined,
    });
  };

export function showValidationErrorNotification(
  i18nNamespace = '',
): OnHttpErrorReturnType<HttpValidationErrorData> {
  const handler = validationErrorNotificationHandler(i18nNamespace);
  return onValidationError(handler);
}

export type HandleHttpErrorsOptions = {
  error: HttpResponseError;
  t: TFunction;
  notifyFn: (payload: NotifyFnPayload) => unknown;
};

export function handleHttpErrors(
  { error, t, notifyFn }: HandleHttpErrorsOptions,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ...handlers: OnHttpErrorReturnType<any>[]
) {
  // default handlers
  const status = error.response?.status ?? HttpStatusCode.InternalServerError;

  const correlationId =
    error.response && error.response.headers
      ? error.response.headers[HTTP_CORRELATION_ID_HEADER_KEY]
      : null;
  const extraDesc = `${t('http.correlation_id')} ${correlationId}`;
  if (status >= HttpStatusCode.InternalServerError) {
    notifyFn({
      message: t('http:errors.generic.title'),
      description: t('http:errors.generic.description'),
      extraDesc,
    });
    return;
  }

  if (
    status === HttpStatusCode.Forbidden ||
    status === HttpStatusCode.Unauthorized
  ) {
    notifyFn({
      message: t('http:errors.forbidden.title'),
      description: t('http:errors.forbidden.description'),
    });
    return;
  }

  for (const [code, handler] of handlers) {
    if (status === code) {
      handler(error, t, notifyFn);
      return;
    }
  }

  if (status === HttpStatusCode.NotFound) {
    notifyFn({
      message: t('http:errors.generic.title'),
      description: t('http:errors.generic.description'),
    });
  }
}
